import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLayoutType, setEventViewColumnsCount } from 'slices/layout';
import { appConfigLoaded } from 'slices/config';
import { useProcessMessageFromHost } from 'utils/sdk-client';
import { isSeamless } from 'appConfiguration';
import { AuthService } from 'services/AuthService';
import Api from 'api';
import { Routing } from './routing';

export function App() {
  const dispatch = useDispatch();
  const [configLoaded, setConfigLoaded] = useState(false);
  const [cfgUpdate, setCfgUpdate] = useState(0);
  const [cfgMessage, setCfgMessage] = useState('');

  const lcode = useSelector((state) => state.config?.appConfig?.activeLangCode);
  const logged = useSelector((state) => state.sports.postLoginLoad);
  useProcessMessageFromHost(isSeamless);
  const manageLayoutByWindowSize = useCallback(() => {
    if (window.innerWidth <= 600) {
      dispatch(setEventViewColumnsCount(1));
    } else {
      dispatch(setEventViewColumnsCount(2));
    }
    if (window.innerWidth <= 1024) {
      dispatch(setLayoutType('mobile'));
    } else {
      dispatch(setLayoutType('default'));
    }
  }, [dispatch]);

  useEffect(() => {
    manageLayoutByWindowSize();
    window.addEventListener('resize', manageLayoutByWindowSize);
  }, [manageLayoutByWindowSize]);

  useEffect(() => {
    dispatch({ type: 'socket/connect' });

    return () => {
      dispatch({ type: 'socket/disconnect' });
    };
  }, [dispatch]);

  useEffect(() => {
    async function fetchData() {
      const configPromise = Api.get('/commence/configuration');
      const languagesPromise = Api.get('/commence/languages');
      const dictionaryPromise = Api.get('/commence/translations');

      configPromise
        .then((result) => {
          const { data } = result;
          if (result.status === 200 && data) {
            AuthService.unauthorised.allowed = !data.loginRequired;
            dispatch(appConfigLoaded({ settings: data }));
          }
        })
        .catch((error) => {
          console.error(error);
        });

      languagesPromise.then((result) => {
        const { data } = result;
        const alcode =
          localStorage.getItem('languageCode') ||
          data?.[0]?.languageCode ||
          'en';
        dispatch(appConfigLoaded({ languages: data, activeLangCode: alcode }));
      });

      dictionaryPromise.then((result) => {
        const { data } = result;
        dispatch(appConfigLoaded({ dictionary: data }));
      });

      Promise.allSettled([configPromise, languagesPromise, dictionaryPromise])
        .then((result) => {
          // console.log(result);
          if (
            Array.isArray(result) &&
            !result.some((rq) => rq.status !== 'fulfilled')
          ) {
            setConfigLoaded(true);
          } else {
            setConfigLoaded(false);
            setCfgMessage('Error in configuration!');
          }
        })
        .catch((error) => {
          setCfgMessage('configuration error!');
          console.error(error);
        })
        .finally(() => console.log('configuration request done!'));
      setConfigLoaded(false);
    }

    fetchData();
  }, [dispatch]);

  useEffect(() => {
    setCfgUpdate((c) => c + 1);
  }, [lcode, logged]);

  return configLoaded ? <Routing key={cfgUpdate} /> : <div>{cfgMessage}</div>;
}
