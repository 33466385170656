const userToken = () => localStorage.getItem('userToken');
const userInfo = () => {
  const token = userToken();
  try {
    return JSON.parse(window.atob(token.split('.')[1]));
  } catch (e) {
    return {};
  }
};

const setUserToken = (token) => {
  localStorage.setItem('userToken', token);
};

const unauthorised = {
  allowed: false,
};

export const AuthService = {
  userToken,
  userInfo,
  setUserToken,
  unauthorised,
};
