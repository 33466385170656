import React, { Suspense, lazy } from 'react';
import { useSelector } from 'react-redux';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Outlet,
  useLocation,
} from 'react-router-dom';

import { AuthService } from 'services/AuthService';
import { isSeamless } from 'appConfiguration';

const Main = lazy(() => import('./pages/main'));
const Login = lazy(() => import('./pages/login'));
const SeamlessLogin = lazy(() => import('./pages/seamlessLogin'));
const UserLoginsReport = lazy(() => import('./pages/reports/userLogins'));
const AccountStatement = lazy(() => import('./pages/reports/accountStatement'));
const BettingHistory = lazy(() => import('./pages/reports/bettingHistory'));
const ProfitLoss = lazy(() => import('./pages/reports/profitLoss'));
const ProfitLossMarket = lazy(() => import('./pages/reports/profitLossMarket'));
const Search = lazy(() => import('./pages/search'));

function RequireAuth() {
  const unauth = useSelector(
    (state) => state.config.appConfig.settings.loginRequired === false
  );
  const isAuthenticated = AuthService.userToken() || (!isSeamless && unauth);
  const location = useLocation();

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return <Outlet />;
}

export function Routing() {
  return (
    <Router>
      <Suspense fallback={<div />}>
        <Routes>
          <Route element={<RequireAuth />}>
            <Route path="/" element={<Main level="root" />} />
            <Route path="/:urlTime" element={<Main />}>
              <Route path=":urlType" element={<Main />}>
                <Route path=":sportId" element={<Main />}>
                  <Route path=":categoryId" element={<Main />}>
                    <Route path=":tournamentId" element={<Main />}>
                      <Route path=":eventId" element={<Main />}>
                        <Route path=":marketId" element={<Main />}>
                          <Route path=":allParam" element={<Main />}>
                            <Route path=":page" element={<Main />}>
                              <Route path=":pageSize" element={<Main />} />
                            </Route>
                          </Route>
                        </Route>
                      </Route>
                    </Route>
                  </Route>
                </Route>
              </Route>
            </Route>
            <Route path="/reports/user_logins" element={<UserLoginsReport />} />
            <Route
              path="/reports/account_statement"
              element={<AccountStatement />}
            />
            <Route
              path="/reports/betting_history"
              element={<BettingHistory />}
            />
            <Route path="/reports/profit_loss" element={<ProfitLoss />} />
            <Route
              path="/reports/profit_loss/:marketId"
              element={<ProfitLossMarket />}
            />
            <Route path="/search" element={<Search />} />
          </Route>
          <Route
            path="/login"
            element={isSeamless ? <SeamlessLogin /> : <Login />}
          />
        </Routes>
      </Suspense>
    </Router>
  );
}
