import { setSmTokenValue, setPartnerConnectedState } from 'slices/user';
import { setSeamlessLayout } from 'slices/layout';
import { appConfigLoaded } from 'slices/config';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { AuthService } from 'services/AuthService';
import Api from 'api';

let partnerWindow = null;

export function sendMessageToHost(message, data = {}) {
  if (partnerWindow) {
    partnerWindow.postMessage(
      { message, type: 'exchanger_msg', data },
      process.env.REACT_APP_IFRAME_PARTNER_URL
    );
  }
}

export function useProcessMessageFromHost(isSeamless) {
  const dispatch = useDispatch();
  const isAuthenticated = AuthService.userToken();

  useEffect(() => {
    if (isSeamless) {
      const logoutUser = async () => {
        await Api.post(`auth/logoff`);
        AuthService.setUserToken('');
        dispatch(setSmTokenValue(''));
        sendMessageToHost('EXCHANGER_LOGGED_OUT');
        // window.location.reload();
      };

      const sendWindowSizeToPartner = () => {
        const data = {
          height: `${document.body.scrollHeight}px`,
        };
        const message = 'EXCHANGER_SIZE_UPDATE';
        sendMessageToHost(message, data);
        console.log(document.body.scrollHeight);
      };

      const manageLayoutByWindowSize = (e) => {
        console.log(e);
        sendWindowSizeToPartner();
      };

      const sendUserStatusToPartner = () => {
        const message = 'EXCHANGER_USER_STATE';
        sendMessageToHost(message, { loggedIn: Boolean(isAuthenticated) });
      };

      const sendHandshake = () => {
        sendMessageToHost('EXCHANGER_HANDSHAKE');
        dispatch(setPartnerConnectedState(true));
      };

      const processMessageFromHost = (e) => {
        if (e.origin !== process.env.REACT_APP_IFRAME_PARTNER_URL) {
          return;
        }
        if (e?.data?.type === 'partner_msg') {
          switch (e.data.message) {
            case 'PARTNER_HANDSHAKE':
              partnerWindow = e.source;
              sendHandshake();
              break;
            case 'PARTNER_REQUEST_STATUS':
              sendUserStatusToPartner();
              break;
            case 'PARTNER_LOGIN_TOKEN':
              console.log(e.data);
              dispatch(setSmTokenValue(e.data.data.token));
              break;
            case 'PARTNER_REQUEST_LOGOUT':
              logoutUser();
              break;
            case 'PARTNER_VIEW_UPDATE':
              dispatch(setSeamlessLayout(e.data.data));
              break;
            case 'PARTNER_SET_LANGUAGE_CODE':
              dispatch(
                appConfigLoaded({ activeLangCode: e.data.data.languageCode })
              );
              break;
            default:
              console.log(e);
          }
        }
      };

      sendWindowSizeToPartner();
      window.addEventListener('resize', manageLayoutByWindowSize);
      window.addEventListener('message', (e) => {
        processMessageFromHost(e);
      });

      const resizeObserver = new ResizeObserver((entries) => {
        console.log('Body height changed:', entries[0].target.clientHeight);
        sendWindowSizeToPartner();
      });

      // start observing a DOM node
      resizeObserver.observe(document.body);
    }
  }, [isSeamless, dispatch, isAuthenticated]);
}
