import { createSlice } from '@reduxjs/toolkit';
import { MEDIA_PROVIDERS } from 'appConfiguration';

import Api from 'api';

const initialState = {
  partnerConfig: {
    mediaProviderIds: { livetracker: MEDIA_PROVIDERS.ODDSDEALER },
    cashoutEnabled: false,
  },
  appConfig: {},
};

export const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    partnerConfigurationLoaded: (state, action) => {
      state.partnerConfig = { ...state.partnerConfig, ...action.payload };
    },
    appConfigLoaded: (state, action) => {
      state.appConfig = { ...state.appConfig, ...action.payload };
    },
  },
});

export const { partnerConfigurationLoaded, appConfigLoaded } =
  configSlice.actions;
export const configReducer = configSlice.reducer;

export const fetchPartnerConfig = () => async (dispatch) => {
  try {
    const { data } = await Api.get(`menu/partnerConfig`);

    dispatch(partnerConfigurationLoaded(data));
  } catch (err) {
    console.error(err);
  }
};
